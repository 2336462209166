<template>
  <div>

    <div class="container">
      <div class="row ">
        <div class="col-12 align-content-center justify-content-center">
          <h1>Результат {{ $route.params.hash }}</h1>
        </div>
      </div>
    </div>

    <div class="container">


      <div class="p-4">
        <form>



          <div class="row mt-4">
            <div class="col-7">
              <!--<a id="div-with-loading-excel" class="btn btn-info vs-con-loading__container ml-3" @click="getStatus"> Узнать статус обработки файла</a>-->
              <a id="div-with-loading-download" @click="downloadFile" class="btn btn-outline-primary ml-3">
                <download-icon class="fea icon-sm"></download-icon> Скачать результаты</a>
            </div>
            <!--<div class="col-5">
            <a id="div-with-loading-youtube" class="btn btn-secondary vs-con-loading__container" @click="getYoutube"> Транскирибировать Youtube</a>

          </div>
          end col-->
          </div>
          <!--end row-->
        </form>
        <!--end form-->
      </div>



    </div>


    <div class="row m-12 mb-5 justify-content-center align-items-center" v-if="this.showStatus">
      Обработано {{ this.status.rows_out }} из {{ this.status.rows_in }} файлов
    </div>



    <div class="row m-12 justify-content-center align-items-center">


      <div class="col-11">
        <div class="table-responsive bg-white shadow rounded">
          <table class="table mb-0 table-center" style="width:100%;">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Время</th>
                <th scope="col">Слушать</th>
                <th scope="col"> Текст сообщений</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(x, ix) in radio2Text">

                <td>{{ ix + 1 }}</td>

                <td>{{ x.file_name_short }}</td>
                <td>
                  <audio-player :src="urlRadio(x.file_name)" />
                  <!--<a @click="getMp3(x.id)" class="btn btn-icon btn-outline-primary"><download-icon class="fea icon-sm"></download-icon></a>-->
                </td>
                <td>
                  <div style="word-wrap: break-word;">{{ x.text }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>





    </div>



  </div>
</template>

<script>
import store from "@/store/store";
import { MessageCircleIcon, BookIcon, DownloadIcon } from "vue-feather-icons";

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export default {
  components: {
    MessageCircleIcon, BookIcon, DownloadIcon,
  },
  name: "SoundFile2Text",
  data() {
    return {
      bestad: {},
      input_api: {
        youtube_url: '',
        user_id: 1,
        hash: '',
      },
      tableShow: false,
      showStatus: false,
      status: {
        rows_in: 0, rows_out: 0,
      },
      token: '',
      excelResults: [],
      refreshTime: 1,

      radio2Text: [],
    }
  },


  computed: {
    avatarForm: function () {
      console.log('inside avatarForm');
      return {
        user_id: store.state.user_id,
      }
    },
  },
  mounted() {
    this.getRadioHistory();
  },
  methods: {




    urlRadio(x) {
      return '' + this.$hostnamejava + '/radio-s3/stream-file/sound2text/' + x;
    },


    downloadFile() {

      this.input_api.user_id = store.state.user_id;
      this.input_api.hash = this.$route.params.hash;
      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/sound2textgrpc/download/excel-results-hash',
        data: this.input_api,
        responseType: "blob",
      }).then(function (response) {
        const blob = new Blob(
          [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        const aEle = document.createElement('a');     // Create a label
        const href = window.URL.createObjectURL(blob);       // Create downloaded link
        aEle.href = href;
        aEle.download = "report.xlsx";  // File name after download
        document.body.appendChild(aEle);
        aEle.click();     // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href) // Release blob object
      })

    },


    getRadioHistory() {

      this.input_api.user_id = store.state.user_id;
      this.input_api.hash = this.$route.params.hash;

      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/sound2textgrpc/get-results-hash',
        data: this.input_api,
      }).then((res) => {

        this.radio2Text = res.data.results;


      });

    },

  }
}
</script>

<style scoped></style>